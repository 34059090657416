.skeleton-tender-card-list{
    display: flex;
    flex-direction: column;
    gap: 32px;
}

.tabpanel-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;

    >div{
        width: 100%;
    }
}
@import '../../styles/mixins/main.scss';

.skeleton-base{
    @extend .skeleton;
    @extend .skeleton-pulse-background;
    margin: unset;
}

$header_height: 160px;
$tab_height: 24px;
$sub_tab_height: 96px;
$tabpanel_height: calc( 
    100% - #{$header_height} - #{$tab_height} - #{$sub_tab_height} 
);

$header_gab: 8px;
$header_max_number_of_figures: 4;
$header_right_width: calc( 
    (#{$header_height} + #{$header_gab}) * #{$header_max_number_of_figures}
    - #{$header_gab}
);
$header_left_width: calc( 
    100% - #{$header_right_width} - #{$header_gab} 
);

$tab_width: 128px;

$sub_tab_bar_height: 40px;
$sub_tab_width: fit-content;
$sub_tab_padding: calc(
    (#{$sub_tab_height} - #{$sub_tab_bar_height}) / 2
);


.page-container {
    @extend .container;
    min-height: 100vh;
    padding: 0 32px;
    padding-top: 16px;
    
    &>.header-container {
        width: 100%;
        height: $header_height;
        
        display: flex;
        flex-direction: row;
        justify-content:space-between;
        align-items: start;
        
        &>.header-left-container{
            width: $header_left_width;
            height: 100%;
            padding-top: 20px;

            display: flex;
            flex-direction: column;
            align-items:start;
            justify-content: space-between;

            &>.header-left-title{
                max-width: 100%;
                height: 20px;

                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                gap:12px;

                @include text-md-bold;
                text-transform: uppercase;

                &.header-left-title-skeleton{
                    @extend .skeleton-base;
                    width: 50%;
                }

                &>.header-left-title-flag {
                    height: 100%;
                    img {
                        height: 14px;   
                        width: auto;
                    }
                }

                &>.header-left-title-name{
                    height: 100%;
                }

                &>.header-left-title-legalform{
                    height: 100%;
                    margin-left: -12px;
                }

                &>.header-left-title-followbutton{
                    height: 100%;
                }
            }

            &>.header-left-info-list{
                width: 100%;

                display: flex;
                flex-direction: column;
                align-items: start;
                justify-content: start;
            }
        }
        &>.header-right-container{
            height: 100%;
            width: $header_right_width;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;
            gap: $header_gab;
            > * {
                width: 25%; 
            }

            &>.header-right-graph{
                height: $header_height;
                width: $header_height;

                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
            }
        }
    }

    &>.maintabs-container {
        position: relative;
        width: 100%;
        height: $tab_height;
        
        display: flex;
        flex-wrap: wrap;
        justify-content: start;
        align-items: start;
        column-gap: 16px;  

        padding: 0 4px;

        border-bottom-color: var(--bg-grey);
        border-bottom-style: solid;
        border-bottom-width: 1px;

        &>.maintab {
            position: relative;
            height: $tab_height;

            @include text-sm-bold;
            text-align: center;

            border-bottom-width: 2px;
            border-bottom-style: solid;
            border-bottom-color: transparent;

            transition: all 0.1s ease;

            &:hover{
                cursor: pointer;
                border-bottom-color: var(--black-5);
                color: var(--black-5);
                opacity: 0.5;
            }

            &.active {
                color: var(--black-3);
                border-bottom-color: var(--black-3);
                opacity: unset;
            };
        }

    }

    &>.subtabs-container {
        width: 100%;
        height: $sub_tab_height;
        padding: $sub_tab_padding 0; 
        
        &>.subtab-bar {
            display: flex;
            width: fit-content;
            height: $sub_tab_bar_height;
            justify-content: start;
            align-items: center;
            flex-direction: row;

            background-color: var(--bg-white);

            border-width: 2px;
            border-style: solid;
            border-color: var(--bg-light-grey);
            border-radius: 4px;

            overflow: hidden;

            &>.subtab {
                @include text;
                height: $sub_tab_bar_height;
                width: $sub_tab_width;
                text-align: center;
                padding: 10px 16px;

                transition: all 0.1s ease;

                &:hover {
                    background-color : var(--bg-light-grey);
                    opacity: 0.5;
                    cursor : pointer;
                }

                &.active{
                    background-color : var(--bg-light-grey);
                    &:hover {
                        opacity: unset;
                        cursor : unset;
                    }

                }
            }
        }
    }

    &>.tabpanel-container{
        height: calc( 100% - #{$header_height} - #{$tab_height} - #{$sub_tab_height} );
    }
}
